import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER, CLEAR_USER } from '../redux/reducers/user';

import './registration.scss';

function RegistrationBlock(props) {
  const dispatch = useDispatch();
  const storage = localStorage.getItem('pbyt');
  let username = useSelector((state) => state.user.username);
  let links = process.env.REACT_APP_COLOR_LINKS;
  const retailer = props.retailer;

  if (!username && storage) {
    const storedUn = JSON.parse(storage).username;
    if (storedUn) {
      username = storedUn;
      dispatch(SET_USER({ state: 2, username: username }));
    }
  }

  function clearUser() {
    dispatch(CLEAR_USER());
    window.location.reload();
  }

  function RenderClearUser() {
    if (username) {
      return (
        <div>
          Not you?
          <button onClick={clearUser} style={{ color: links }}>
            Forget User
          </button>
        </div>
      );
    }
    return '';
  }

  function trackRegistrationEvent (){
    window.gtag('event', 'registration_link_click', { 
      retailer: retailer.dpn.name
    });
  }

  return (
    <section className="register-bar">
      {!username && (
        <span>
          Not a registered {retailer.dpn.name} user yet?{' '}
          <a
            href={retailer.registration.link}
            onClick={trackRegistrationEvent}
            target="_blank"
            style={{ color: links }}
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </span>
      )}
      <RenderClearUser />
    </section>
  );
}

export default RegistrationBlock;
