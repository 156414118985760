import React from 'react';
import {useParams} from "react-router-dom";
import OfferSection from './section.js';

import './styles.scss';

function RetailerContent(props) {
    // Change Retailer  
    const { retailerName } = useParams();
    const retailers = props.retailersList.retailers;
    const retailer = retailers.find(obj=>obj.dpn.id === retailerName);
    const {
        REACT_APP_COLOR_LINKS: links,
      } = process.env;

    function ChangeRetailer(){
        return (
            <div className='changeRetailer'>
                <div className='selected'>
                    <p><strong>Selected Retailer</strong></p>
                    <p>{retailer.dpn.name}</p>
                </div>
                <div className='change'>
                    <a 
                        href='/'
                        style={{ color: links }}
                    >Change Retailer</a>
                </div>
            </div>
        )
    }
  
    return (
        <div className='retailerContent'>
            <ChangeRetailer></ChangeRetailer>
            <OfferSection retailer={retailer}></OfferSection>
        </div>
    );
}
export default RetailerContent;
