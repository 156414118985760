import React from 'react';
import RecipeSectionItem from './section';

import './recipe.scss';

//display recipe content area
function RecipeContent(props) {
  //props fetch data from recipe.json
  const recipeStlyes = props.recipes.customStyles ? 'recipe-content-page custom':'recipe-content-page default';
  
  function SectionTitle(){
    if(props.recipes.sectionHeaderImage){
      return <img className='sectionTitle' src={props.recipes.sectionHeaderImage} alt={props.recipes.sectionHeader}/>
    }
    return <h2 className='sectionTitle'>Recipes</h2>
  }
  
  //list recipe sections
  return (
    <div className={(recipeStlyes)} id="recipesection">
      <div className='recipeArea'>
        <SectionTitle/>
        <div className="recipe-content">
          {props.recipes.recipeSection?.map((item, i) => {
            return <RecipeSectionItem section={item} key={i}></RecipeSectionItem>;
          })}
        </div>
      </div>
      <div className='clearer'></div>
    </div>
  );
}
export default RecipeContent;
