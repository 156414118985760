import { retailer } from "./retailer";
import queryString from "query-string";
import { content } from "./content";


const hostname = window.location.hostname;
// const isStaging = hostname.includes('cloudfront');
const isLocalhost = hostname.includes('localhost');
const useTest = isLocalhost;
const sub = useTest ? "test." : "";

const parsed = queryString.parse(window.location.search);
const config = {
  myEnv: isLocalhost ? 'local' : 'production',
  content: content,
  retailer: retailer,
  baseV2Url: parsed.testing ? "" : "https://ice." + sub + "dpn.inmar.com/v2/",
  baseUrl: parsed.testing ? "" : "https://ice." + sub + "dpn.inmar.com/v3/",
  originUrl: isLocalhost ? `${window.location.origin}/` : "",
  urlParam: parsed
};
export default Object.freeze(config);
