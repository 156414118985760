import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import store from '../redux';
import ReactHtmlParser from 'react-html-parser';

function LoginModal(props) {
  const dispatch = useDispatch();
  const {
    offer: { show_login: show, id },
    config
  } = useSelector((state) => state);
  const handleClose = () => {
    setLoginError(false);
    dispatch(SHOW_LOGIN({ show: false }));
  };
  
  // find better solution
  const retailerName = window.location.pathname.split("/retailers/").pop();
  const retailers = config.retailer.retailers;
  const retailer = retailers.find(obj=>obj.dpn.id === retailerName);

  let rememberUser = useRef(false);
  let [username, setUserName] = useState();
  let [loginError, setLoginError] = useState(false);
  let [isDisabled, setEnableBtn] = useState(true);
  let mpidbyEnv = store.getState().config.myEnv;
  let dpn_id = retailerName === '/' ? ' ' : retailer.dpn.id;
  let dpn_name = retailerName === '/' ? ' ' : retailer.dpn.name;
  let mpid = mpidbyEnv !== 'production' ? '' : process.env.REACT_APP_MPID;
  let offerTag = mpidbyEnv !== 'production' ? '' : process.env.REACT_APP_OFFER_TAG;
  let funnel = process.env.REACT_APP_FUNNEL ? process.env.REACT_APP_FUNNEL : 'n/a';
  let channel = process.env.REACT_APP_CHANNEL ? process.env.REACT_APP_CHANNEL : 'n/a';
  // let marketingEvent = process.env.REACT_APP_MARKETING_EVENT ? process.env.REACT_APP_MARKETING_EVENT : 'n/a';

  function checkModalValidation(e) {
    const value = retailer.login.usernameType;
    const username = retailer.login.username;
    const userInput = e.target.value;
    const regexp = /^[0-9]{10}$/;
    const splChar = /[-() ]/;
    const validCard = /^\d+\.?\d*$/;
    const validEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // phone
    if(value==='phone'){
      //Checking if input field is empty
      if (userInput === '') {
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(true);
      }
      // Checking for valid numbers
      else if (regexp.test(userInput)) {
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(false);
      }
      // Checking for spaces, parenthesises, or hyphens
      else if (splChar.test(userInput)) {
        setLoginError(username + ' must be digits');
      } else {
        setLoginError(username + ' must be 10 digit numbers');
        setEnableBtn(true);
      }
    }
    // card
    if(value==='card'){
      //Checking if input field is empty
      if (userInput === '') {
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(true);
      }
      // Checking for valid numbers
      else if (validCard.test(userInput)) {
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(false);
      }
      // Checking for spaces, parenthesises, or hyphens
      else if (splChar.test(userInput)) {
        setLoginError(username + ' must be digits');
      } else {
        setLoginError(username + ' must be numbers');
        setEnableBtn(true);
      }
    }
    // email
    if(value==='email'){
      //Checking if input field is empty
      if (userInput === '') {
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(true);
      }
      // check if valid email
      else if(validEmail.test(userInput)){
        setLoginError(false);
        setUserName(userInput);
        setEnableBtn(false);
      }
      else {
        setLoginError(username + ' must be valid email address');
        setEnableBtn(true);
      }
    }
  
  }

  function handleValidation() {
    if (username) {
      const options = {
        endpoint: 'offers/load',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'xretailer': dpn_id,
          Origin: window.location.origin,
        },
        body: { 
          username: username, 
          id: id, 
          mediaPropertyId: mpid,
          tags: offerTag,
          channel: channel, 
          funnel: funnel
        },
        retailer: dpn_name
      };

      dispatch(CLIP_OFFER(options)).then((resp) => {
        if (resp.payload.success) {
          dispatch(SET_USER({ state: 2, username: username }));
          if (rememberUser.current.checked) {
            saveToStorage(username, JSON.stringify(resp.payload.id));
          }
          trackLoginEvent();
          handleClose();
        } else {
          console.log('err',resp.payload.errorMsg)
          setLoginError(resp.payload.errorMsg);
        }
      });
    } else {
      setLoginError('Member Id required');
    }
  }
  function saveToStorage(username, id) {
    const obj = { username: username, myIds: id };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }
  function RenderLoginError() {
    if (loginError) {
      return <div className="error text-danger">{loginError}</div>;
    }
    return '';
  }
  function trackLoginEvent (){
    window.gtag('event', 'account_login', { 
      retailer_id: dpn_id,
      retailer: dpn_name
    });
  }
  if (show) {
    const {
      REACT_APP_COLOR_PRIMARY: primary,
      REACT_APP_COLOR_SECONDARY: secondary,
    } = process.env;
    return (
      <div
        className="modal fade show"
        id="loginModal"
        style={{ display: show ? 'block' : 'none' }}
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: '800px' }}
        >
          <div className="modal-content text-justify">
            <div className="modal-header">
              <div className="modal-title">
                <h4>{retailer.login.title}</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor={retailer.login.usernameType}>{retailer.login.username}</label>
                  <input
                    type={retailer.login.usernameType}
                    onChange={(e) => {checkModalValidation(e);}}
                    className="form-control"
                  />
                  <div className="form-check">
                    <input
                      type="checkbox"
                      ref={rememberUser}
                      className="form-check-input"
                      id="formLogin"
                    />
                    <label htmlFor="formLogin" className="form-check-label">
                      Remember me on this computer
                    </label>
                  </div>
                  <small className="text-muted">{ReactHtmlParser(retailer.login.legal)} </small>
                  <RenderLoginError />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                style={{ color: primary }}
                onClick={handleClose}
                variant="link"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                disabled={isDisabled}
                style={
                  isDisabled
                    ? { backgroundColor: secondary }
                    : { backgroundColor: primary }
                }
                onClick={handleValidation}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
}
export default LoginModal;
