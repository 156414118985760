import React from 'react';
import { Link } from "react-router-dom";

import './styles.scss';

function RetailerList(props) {   
    function Retailer(){
        const retailer = props.retailersList.retailers.sort((a,b) => a.dpn.name.localeCompare(b.dpn.name));
        const selectedRetailer = props.selectedRetailers;
        const selectedRetailerArray = Object.values(selectedRetailer);
        const filteredRetailers = retailer.filter(retailer => selectedRetailerArray.some(selected => retailer.dpn.id === selected.id));

        function trackRetailerEvent (name){
          window.gtag('event', 'retailer_selection', { 
            retailer: name
          });
        }

        return (
          <div className='list'>
            {filteredRetailers.map((item, i) => {
              const linkname = item.dpn.id.replace(/\s/g, '').toLowerCase();
              return(
                <Link 
                  to={{
                    pathname:"/retailers/"+linkname
                  }} 
                  className='icons' 
                  key={i}
                  onClick={() => trackRetailerEvent(item.dpn.name)}
                >
                  <p>{item.dpn.name}</p>
                  <div className='image_block'>
                    <img
                      className={`logo`}
                      src={`/images/retailers/`+ item.images.logo}
                      alt={item.dpn.name}
                    />
                  </div>
                </Link>
              )
            })}
          </div>
        )
      }
      return (
          <div className='retailerList'>
              <h2>Choose a retailer to see savings</h2>
              <Retailer></Retailer>
          </div>
      );
}
export default RetailerList;