import React, { useState } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
// import queryString from 'query-string';
//app components
// import RegistrationBlock from './registration';
import LoginModal from './loginModal';
import Hero from './hero';
import Content from './content';
import Cart from './cart';
import Recipes from './recipes';
import RecipeContent from './recipes/recipe.js';
import RetailerList from './retailList';
import RetailerContent from './retailerContent';
import Spinner from './common/Spinner';
import ErrorToast from './common/ErrorToast';
import PageNotFound from './404';


//styles
import './App.css';

require (`./styles/${process.env.REACT_APP_BRAND_NAME}/styles.scss`);

const history = createBrowserHistory();

function App() {
  const {
    config
  } = useSelector((state) => state);
  let [isLoading, updateLoadingState] = useState(true);
  let [isFetching, updateFetchState] = useState(false);
  let [recipeData, setRecipes] = useState([]);
  let [hero, setHero] = useState([]);
  const [retailerInfo, setretailerInfo] = useState([]);


  let showLogin = useSelector((state) => state.offer.show_login);

  function fetchRetailer(){
    let retailList = '../json/' + process.env.REACT_APP_BRAND_NAME + '/retailers.json';
    
    fetch( retailList, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setretailerInfo(data)
    });
  }
  function fetchRecipes(){
    let recipeInfo = '/json/' + process.env.REACT_APP_BRAND_NAME + '/recipe.json';
    
    fetch( recipeInfo, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setRecipes(data)
    });
    
  }
  function fetchHero(){
    updateFetchState(true);
    let heroInfo = '/json/' + process.env.REACT_APP_BRAND_NAME + '/hero.json';
    
    fetch( heroInfo, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setHero(data)
    })
    .then(() => {
      updateLoadingState(false);
    });
    
  }

  function googleAnalytics() {
    var gaId = process.env.REACT_APP_GA;

    var galink = document.createElement('script');
    galink.src = 'https://www.googletagmanager.com/gtag/js?id='+gaId;
    
    var galink2 = document.createElement('script');
    galink2.innerHTML =
      'window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag(\'js\', new Date()); gtag(\'config\', \'' + gaId + '\');'

    if(gaId){
      document.head.appendChild(galink);
      document.head.appendChild(galink2);
    }
  }
  function inmarAnalytics() {
    var iaId = process.env.REACT_APP_INMAR_ANALYTICS;

    var ialink = document.createElement('script');
    ialink.innerHTML = 'window._oiqq = window._oiqq || [];  _oiqq.push([\'oiq_addPageLifecycle\', \'' + iaId + '\']); _oiqq.push([\'oiq_doTag\']); (function() { var oiq = document.createElement(\'script\'); oiq.type = \'text/javascript\'; oiq.async = true; oiq.src = \'https://px.owneriq.net/stas/s/br53cg.js\'; var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(oiq, s);})();';
    
    if (iaId){
      document.head.appendChild(ialink);
    }
  }

  function ModalBackdrop() {
    if (showLogin) {
      return <div className="modal-backdrop fade show"></div>;
    } else {
      return '';
    }
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  if (!isFetching) {
    fetchHero();
    fetchRetailer();
    googleAnalytics();
    inmarAnalytics();
    if(process.env.REACT_APP_HAS_RECIPES === 'true'){
      fetchRecipes();
    }
  }
  if (isLoading) {
    return <Spinner />;
  } else {
    const {
      REACT_APP_COLOR_BACKGROUND: background,
      REACT_APP_HAS_CART_ITEMS: hasCart,
      REACT_APP_HAS_CONTENT: hasContent,
      REACT_APP_HAS_RECIPES: hasRecipes,
    } = process.env;

    const showCart = hasCart === 'true';
    const showContent = hasContent === 'true';
    const showRecipes = hasRecipes === 'true';

    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor:background,
        }}
      >
        
        <BrowserRouter history={history}>
          <div className={(`App-content container ${process.env.REACT_APP_BRAND_NAME}`)} id="App-content">
            <Switch>
              <Route path={['/', '/home']} exact>
                <Hero hero={hero}/>
                <RetailerList retailersList={config.retailer} selectedRetailers={retailerInfo}/>
                {showCart && <Cart />} 
              </Route>
              <Route path="/recipes/:recipeName" >
                <RecipeContent recipes={recipeData} />
              </Route>
              <Route path="/retailers/:retailerName" >
                <Hero hero={hero}/>
                <RetailerContent retailersList={config.retailer} selectedRetailers={retailerInfo} recipes={recipeData}/>
                {showRecipes && <Recipes recipes={recipeData}/>}
                {showContent && <Content />}
              </Route>
              <Route path="*" component={PageNotFound} />
            </Switch>
            <ErrorToast />
          </div>
        </BrowserRouter>
        <LoginModal />
        <ModalBackdrop></ModalBackdrop>
      </div>
    );
  }
}
export default App;
