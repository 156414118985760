import React, { useState } from 'react';
import { SET_OFFERS } from '../redux/reducers/offer';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import store from '../redux';
import Offers from '../offers';
import Spinner from '../common/Spinner';
import RegistrationBlock from '../registration';

import './styles.scss';

function OfferSection(props) {
    const dispatch = useDispatch();
    const retailer = props.retailer
    
    const {
        config,
        config: {
          urlParam: { ids: offerIds },
        },
    } = useSelector((state) => state);
    let [isLoading, updateLoadingState] = useState(true);
    let [isFetching, updateFetchState] = useState(false);
    let [storedClips, updateStoredClips] = useState({ list: [] });
    let featuredOffers = config.urlParam.fid || config.urlParam.FID;
    let dpn_id = retailer.dpn.id;
    let mpidbyEnv = store.getState().config.myEnv;
    let mediaPId = mpidbyEnv !== 'production' ? '' : process.env.REACT_APP_MPID;
    let offerTag = mpidbyEnv !== 'production' ? process.env.REACT_APP_OFFER_TAG : process.env.REACT_APP_OFFER_TAG;
    
    let offers = {
        clipped: [],
        available: [],
        featured: []
    };
    function parseOffers(data) {
        let storage = localStorage.getItem('pbyt');
        if (storage) {
          const storedObj = JSON.parse(storage);
    
          updateStoredClips({ list: storedObj.myIds ? storedObj.myIds : [] });
        }
        let parsed = offers;
        if (data) {
          data.forEach((offer, idx) => {
            const display_start_date = new Date(offer.clipStartDate);
            const display_end_date = new Date(offer.clipEndDate);
            offer.display_start_date = display_start_date;
            offer.display_end_date = display_end_date;
            if (storedClips.list.includes(offer.id)) {
              Object.assign(offer, { clipped: true });
            }
            if (
              featuredOffers &&
              featuredOffers.includes(offer.id)
            ) {
              parsed.featured.push(offer);
            } else {
              parsed.available.push(offer);
            }
          });
        }
        dispatch(SET_OFFERS({ collections: parsed }));
        return true;
    }

    function fetchOffers() {
        //prevent refetch if already started
        updateFetchState(true);
    
        let url = 'offers';
        if (config.urlParam.testing) {
          url = '/json/coupons.json';
        }
    
        let urlObject = {};
        if (offerIds && !featuredOffers) {
          urlObject.ids = offerIds;
        } else if (mediaPId && retailer.dpn.parent === 'dpn' ) {
          urlObject.mediaPropertyId = mediaPId;
        } else if (retailer.dpn.parent === 'yt') {
          urlObject.tags = offerTag;
        }
        url += '?limit=999&' + queryString.stringify(urlObject);
        fetch(config.baseUrl + url, {
          headers: {
            'Content-Type': 'application/json',
            'x-retailer': dpn_id,
            Origin: window.location.origin,
            'If-Modified-Since': new Date(),
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.items.length > 0) {
              parseOffers(data.items);
            }
          })
          .then(() => {
            updateLoadingState(false);
          });
    }

    if (!isFetching) {
      fetchOffers();
    }
    if (isLoading) {
      return <Spinner />;
    } else {
      return (
        <div className={'offerSection'}>
          <RegistrationBlock offers={offers} retailer={retailer} />
          <Offers offers={offers} retailer={retailer} />
        </div>
      );
    }
}
export default OfferSection;
