export const retailer = {
  retailers: [
    // Publix
    {
      dpn: {
        id: 'publix',
        name: 'Publix',
        parent:'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'publix.png',
      },
      login: {
        title:'Add your Publix Digital Coupon Member ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Publix Digital Coupon Member ID is your phone number.  No spaces, parenthesises, or hyphens can be used when entering your Member ID. If you don\'t have a Club Publix Account <a href="https://www.publix.com/myaccount/register" target="_blank" rel="noopener noreferrer">click here</a>&nbsp;on the web to create one.'
      },
      registration:{
        link:'https://www.publix.com/myaccount/register'
      }
    },
    // Family Dollar
    {
      dpn: {
        id: 'familydollar',
        name: 'Family Dollar',
        parent:'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'familydollar.png',
      },
      login: {
        title:'Add your Smart Coupons ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Smart Coupons ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Celebrate Rewards account, <a href="https://www.familydollar.com/create-account?screenToRender=traditionalRegistration" target="_blank" rel="noopener noreferrer">click here</a>&nbsp;on the web to create one.',
      },
      registration:{
        link:'https://www.familydollar.com/create-account?screenToRender=traditionalRegistration'
      }
    },
    // Brookshire Grocery Company
    {
      dpn: {
        id: 'brookshiregrocery',
        name: 'Brookshire Grocery Company',
        parent: 'yt'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'brookshiregrocerycompany.png',
      },
      login: {
        title:'Add your Thank You Card Number',
        username: 'Loyalty Number',
        usernameType: 'card',
        legal:'Your Thank You Card Number is your loyalty number. No spaces, parentheses, or hyphens should be used when entering your loyalty number. If you don\'t have a loyalty number, <a href="https://www.brookshires.com/user_info/register" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.brookshires.com/user_info/register'
      }
    }, 
    // Brookshire Brothers
    {
      dpn: {
        id: 'brookshirebros',
        name: 'Brookshire Brothers',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'brookshirebrothers.png',
      },
      login: {
        title:'Add your Celebrate Rewards ID',
        username: 'phone number',
        usernameType: 'phone',
        legal:'Your Celebrate Rewards ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Celebrate Rewards account, <a href="https://brookshirebrothers.force.com/s/bbrosregister" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://brookshirebrothers.force.com/s/bbrosregister'
      }
    },
    // Cub Food
    {
      dpn: {
        id: 'cub',
        name: 'Cub Foods',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'cub.png',
      },
      login: {
        title:'Add your My Cub Rewards ID',
        username: 'Membership ID',
        usernameType: 'card',
        legal:'Your My Cub Rewards ID is your Cub Rewards membership id. No spaces, parentheses, or hyphens should be used when entering your membership id. If you don\'t have a My Cub Rewards account, <a href="https://login.cub.com/cublogin.onmicrosoft.com/b2c_1a_p1_v1_susi_prod/oauth2/authorize?client_id=f77b54dd-8864-4798-8885-77c7e91420df&redirect_uri=https%3A%2F%2Fsts.cub.com%2Fsignin-oidc-sts&response_type=code&scope=openid&code_challenge=QeHAUZZm-71Wgc6wwIB9KGZmek43fezDmULn18yrPAk&code_challenge_method=S256&response_mode=form_post&nonce=638133818785864082.ZTg5OWE2MzMtODQ1OC00MzM5LWFjNDYtZjU0YjNmNWRkMTM0ODNlOGZlMzYtMzMzYy00MDYxLTlhMmEtYzIwZmQ0MjY1NTIy&state=CfDJ8OVU5FUKPtNCrYzK_mN45yuS_qNH4sE-U9JjoSmG0yP7si4SKwJ1JgJjsFQHX27mEZ7lJmlpW-ddrcRSkViKpF3oqX1aB2_oOD1eirj3zD2QiAQdKRWDPSpoa5rPkidTHmaXK99DypWKxY7ybxJq9bDiEfE5KFko4igCbs8oQjk_ET447zn6le02kyN8chN11eL1WJYOn3_pCb6EdUATHbMZnxqoOfXmxAtZ82Dn91hMIkU8aKfeaH4H0FdG4KlvQkwuZSXH75HmnXJeaBW3VIwwVqER0iK7KtzxMXBqsE3M6rklsYE9FWQ31U0kx8d4eVjSyOwPIv-uC-lbQP3sgZcYCdvh4TnF6_MaTkk54JBmh6_nrDpD5eMsyR7JavEYGuAVPgYX77pqb7s3Uz7Hx0y7qJz30OZGMm5xyW39hlqGf1Nru7uqcKkTeK_WlJayetsjnlV_UqFaoP0gejEpGjcBaI1PjKQh8AW59GUBcPAzv747I2kAexFg3m9DVpj1kIZ6xNdNaArqJJ9Hqnqvfkhcxf0rwquLLJ2tmO3R7oqPAPWIb1Iq2VzjuhCfgmHu5yJzRDPlA7P-pHe8IcMFXr3WpFcRqwTVWALgUGNVZkwn_lDaUko0Gcn-pcEfvRO0TfGQ0QmU9G0OITvmSmNM_dvOkKmBSRyUD1J9WD8REkBM6-7o4X_Cylr9-rTcNhcsyria-9673SHeUmrO6Put_Aby9fmo4t81eS-dDEQJ3SB6Y4j6QW7fULBeMJRXz4bn1Zfs-OBKi3Av4v9_5F2BQ6utLsoKh4jsPIBt007J9mOSVeFGBI7fh4v-B8UpikY5R8LPOf7UTi1x4qAuwZMYBCj3_kw27BagTLV80MME7fcis7xie5HDU5ig4JS87KW_I5cxVTVeAW421KQzioMB4Pk2R_gF_7gB7wGwFK0Nd56_jDwoDR9osoxGTTRQShELV8gidTypcrrfrQTqEtWjY4aYqtXod30ZvnT3sTaQ8UKzlOwHELzPZxr6puZMqm8TBaDVkAmYoZb1zthXpdRzKLx1qmfHT10obt5eJPs4AqSF5IvPbnb7Qi5qfKW3IsI1Gg&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=5.5.0.0" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://login.cub.com/cublogin.onmicrosoft.com/b2c_1a_p1_v1_susi_prod/oauth2/authorize?client_id=f77b54dd-8864-4798-8885-77c7e91420df&redirect_uri=https%3A%2F%2Fsts.cub.com%2Fsignin-oidc-sts&response_type=code&scope=openid&code_challenge=QeHAUZZm-71Wgc6wwIB9KGZmek43fezDmULn18yrPAk&code_challenge_method=S256&response_mode=form_post&nonce=638133818785864082.ZTg5OWE2MzMtODQ1OC00MzM5LWFjNDYtZjU0YjNmNWRkMTM0ODNlOGZlMzYtMzMzYy00MDYxLTlhMmEtYzIwZmQ0MjY1NTIy&state=CfDJ8OVU5FUKPtNCrYzK_mN45yuS_qNH4sE-U9JjoSmG0yP7si4SKwJ1JgJjsFQHX27mEZ7lJmlpW-ddrcRSkViKpF3oqX1aB2_oOD1eirj3zD2QiAQdKRWDPSpoa5rPkidTHmaXK99DypWKxY7ybxJq9bDiEfE5KFko4igCbs8oQjk_ET447zn6le02kyN8chN11eL1WJYOn3_pCb6EdUATHbMZnxqoOfXmxAtZ82Dn91hMIkU8aKfeaH4H0FdG4KlvQkwuZSXH75HmnXJeaBW3VIwwVqER0iK7KtzxMXBqsE3M6rklsYE9FWQ31U0kx8d4eVjSyOwPIv-uC-lbQP3sgZcYCdvh4TnF6_MaTkk54JBmh6_nrDpD5eMsyR7JavEYGuAVPgYX77pqb7s3Uz7Hx0y7qJz30OZGMm5xyW39hlqGf1Nru7uqcKkTeK_WlJayetsjnlV_UqFaoP0gejEpGjcBaI1PjKQh8AW59GUBcPAzv747I2kAexFg3m9DVpj1kIZ6xNdNaArqJJ9Hqnqvfkhcxf0rwquLLJ2tmO3R7oqPAPWIb1Iq2VzjuhCfgmHu5yJzRDPlA7P-pHe8IcMFXr3WpFcRqwTVWALgUGNVZkwn_lDaUko0Gcn-pcEfvRO0TfGQ0QmU9G0OITvmSmNM_dvOkKmBSRyUD1J9WD8REkBM6-7o4X_Cylr9-rTcNhcsyria-9673SHeUmrO6Put_Aby9fmo4t81eS-dDEQJ3SB6Y4j6QW7fULBeMJRXz4bn1Zfs-OBKi3Av4v9_5F2BQ6utLsoKh4jsPIBt007J9mOSVeFGBI7fh4v-B8UpikY5R8LPOf7UTi1x4qAuwZMYBCj3_kw27BagTLV80MME7fcis7xie5HDU5ig4JS87KW_I5cxVTVeAW421KQzioMB4Pk2R_gF_7gB7wGwFK0Nd56_jDwoDR9osoxGTTRQShELV8gidTypcrrfrQTqEtWjY4aYqtXod30ZvnT3sTaQ8UKzlOwHELzPZxr6puZMqm8TBaDVkAmYoZb1zthXpdRzKLx1qmfHT10obt5eJPs4AqSF5IvPbnb7Qi5qfKW3IsI1Gg&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=5.5.0.0'
      }
    },
    // DeCa
    {
      dpn: {
        id: 'deca',
        name: 'DeCa',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip & Save',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'deca.jpg',
      },
      login: {
        title:'Add your DeCA Rewards ID',
        username: 'Loyalty Card Number',
        usernameType: 'card',
        legal:'Your DeCA Rewards ID is your DeCA Rewards loyalty card number. No spaces, parentheses, or hyphens should be used when entering your loyalty card number. If you don\'t have a DeCA Rewards account, <a href="https://shop.commissaries.com/my-account#!/create" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://shop.commissaries.com/my-account#!/create'
      }
    },  
    // Food City
    {
      dpn: {
        id: 'foodcity',
        name: 'Food City',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Load to Card',
          clipped:'Loaded',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'food_city.png',
      },
      login: {
        title:'Add your ValuCard number',
        username: 'Loyalty Card Number',
        usernameType: 'card',
        legal:'Your ValuCard number is your loyalty card number. No spaces, parentheses, or hyphens should be used when entering your loyalty card. If you don\'t have a ValuCard account, <a href="https://www.foodcity.com/home/login/" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.foodcity.com/home/login/'
      }
    },   
    // FoodMaxx
    {
      dpn: {
        id: 'foodmaxx',
        name: 'FoodMaxx',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'foodmaxx.png',
      },
      login: {
        title:'Add your MAXXcoupons Loyalty ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your MAXXcoupons Loyalty ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a MAXXcoupons account, <a href="https://www.foodmaxx.com/maxxcoupons-signup" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.foodmaxx.com/maxxcoupons-signup'
      }
    },  
    // Fresh Thyme
    {
      dpn: {
        id: 'freshthyme',
        name: 'Fresh Thyme',
        parent: 'yt'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'fresh_thyme.png',
      },
      login: {
        title:'Add your MyThyme Loyalty ID',
        username: 'Loyalty Number',
        usernameType: 'card',
        legal:'Your MyThyme Loyalty ID is your loyalty number. No spaces, parentheses, or hyphens should be used when entering your loyalty number. If you don\'t have a MyThyme Loyalty account, <a href="https://auth.freshthyme.com/register" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://auth.freshthyme.com/register'
      }
    },   
    // Lowes Foods
    {
      dpn: {
        id: 'lowesfds',
        name: 'Lowes Foods',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip!',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'lowes_foods.png',
      },
      login: {
        title:'Add your Fresh Rewards Card Number',
        username: 'Card Number',
        usernameType: 'card',
        legal:'Your Fresh Rewards Card Number is your card number. No spaces, parentheses, or hyphens should be used when entering your card number. If you don\'t have a Fresh Rewards account, <a href="https://identity.lowesfoods.com/lowesfoodsidentity.onmicrosoft.com/B2C_1A_signup_signin/oauth2/v2.0/authorize?client_id=e3d0fa76-5532-45ea-a792-e8bc62fc7a9f&prompt=login&redirect_uri=https:%2F%2Ffalcon.shop.inmar.io%2Fv1%2Fauth%2Fazure-b2c&response_mode=form_post&response_type=code&scope=openid%20offline_access&state=%7B%22redirectTo%22:%22%2Fpromotions%22%2C%22sessionId%22:%222MwY9FvRTWELnI7SNfzSpM%22%2C%22environmentName%22:%22prod%22%2C%22policyName%22:%22B2C_1A_signup_signin%22%7D" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://identity.lowesfoods.com/lowesfoodsidentity.onmicrosoft.com/B2C_1A_signup_signin/oauth2/v2.0/authorize?client_id=e3d0fa76-5532-45ea-a792-e8bc62fc7a9f&prompt=login&redirect_uri=https:%2F%2Ffalcon.shop.inmar.io%2Fv1%2Fauth%2Fazure-b2c&response_mode=form_post&response_type=code&scope=openid%20offline_access&state=%7B%22redirectTo%22:%22%2Fpromotions%22%2C%22sessionId%22:%222MwY9FvRTWELnI7SNfzSpM%22%2C%22environmentName%22:%22prod%22%2C%22policyName%22:%22B2C_1A_signup_signin%22%7D'
      }
    },  
    // Lucky Supermarket
    {
      dpn: {
        id: 'luckysuper',
        name: 'Lucky Supermarket',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'luckysupermarket.png',
      },
      login: {
        title:'Add your Lucky You Rewards Loyalty Card ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Lucky You Rewards Loyalty Card ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Lucky You Rewards account, <a href="https://www.luckysupermarkets.com/loyalty-signup" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.luckysupermarkets.com/loyalty-signup'
      }
    },  
    // Marc’s
    {
      dpn: {
        id: 'marcsstores',
        name: 'Marc\'s',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Click It',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'marcs.png',
      },
      login: {
        title:'Add your My Marc\'s Savings ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Marc\'s Savings ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Marc\'s Savings Account, <a href="https://www.marcs.com/My-Marcs-Login/Coupon-Sign-Up" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.marcs.com/My-Marcs-Login/Coupon-Sign-Up'
      }
    },  
    // Price Chopper
    {
      dpn: {
        id: 'pricechopperdpn',
        name: 'Price Chopper',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'pricechopper.png',
      },
      login: {
        title:'Add your AdvantEdge Rewards Loyalty ID',
        username: 'Card Number',
        usernameType: 'card',
        legal:'Your AdvantEdge Rewards Loyalty ID is your card number. No spaces, parentheses, or hyphens should be used when entering your card number. If you don\'t have a AdvantEdge Rewards account, <a href="https://shop.pricechopper.com/login?redirect=https:%2F%2Fwww.pricechopper.com&norender=1" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://shop.pricechopper.com/login?redirect=https:%2F%2Fwww.pricechopper.com&norender=1'
      }
    },  
    // SaveMart
    {
      dpn: {
        id: 'savemartdpn',
        name: 'SaveMart',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'savemart.png',
      },
      login: {
        title:'Add your Save Smart Rewards ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Save Smart Rewards ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Save Smart Rewards account, <a href="https://www.savemart.com/loyalty-signup" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.savemart.com/loyalty-signup'
      }
    },   
    // Schnucks
    {
      dpn: {
        id: 'schnucks',
        name: 'Schnucks',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip & Save',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'schnucks.png',
      },
      login: {
        title:'Add your Schnucks Rewards ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Schnucks Rewards ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Schnucks Rewards account, <a href="https://signin.schnucks.com/u/signup?state=hKFo2SB6WDZ0Ukh4eDkwMXJkTl9OSmNKX0UtSEhKTHlyWTNsTqFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIGxIekNiQjVySWxFLTBtUWtobGMxamZxeXdCZjQ1eTJSo2NpZNkgY3d4UTdJNTBMcUZwQnJvNlNSWE9oSmpzaFprVm9VNHY" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://signin.schnucks.com/u/signup?state=hKFo2SB6WDZ0Ukh4eDkwMXJkTl9OSmNKX0UtSEhKTHlyWTNsTqFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIGxIekNiQjVySWxFLTBtUWtobGMxamZxeXdCZjQ1eTJSo2NpZNkgY3d4UTdJNTBMcUZwQnJvNlNSWE9oSmpzaFprVm9VNHY'
      }
    },  
    // Smart and Final
    {
      dpn: {
        id: 'smartandfinal',
        name: 'Smart and Final',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'smart_final.png',
      },
      login: {
        title:'Add your Smart Advantage Member ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Smart Advantage Member ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Smart Advantage account, <a href="https://www.smartandfinal.com/user_info/register" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.smartandfinal.com/user_info/register'
      }
    },  
    // Family Fare
    {
      dpn: {
        id: 'spartan',
        name: 'Family Fare',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'family_fare.jpeg',
      },
      login: {
        title:'Add your YES Card Number',
        username: 'Card Number',
        usernameType: 'card',
        legal:'Your YES Card Number is the first 11 digits of your card number. No spaces, parentheses, or hyphens should be used when entering your card number. If you don\'t have a YES Rewards account, <a href="https://www.shopfamilyfare.com/p/signup" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.shopfamilyfare.com/p/signup'
      }
    },    
    // Tops
    {
      dpn: {
        id: 'tops',
        name: 'Tops',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Add',
          clipped:'Added',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'tops.jpg',
      },
      login: {
        title:'Add your TOPS BonusPlus® Card Number',
        username: 'Loyalty Card Number',
        usernameType: 'card',
        legal:'Your TOPS BonusPlus® Card Number is your loyalty card number. No spaces, parentheses, or hyphens should be used when entering your loyalty card number. If you don\'t have a TOPS BonusPlus® account, <a href="https://www3.topsmarkets.com/consumers/retailers/767/accounts/new?skip_verification=t" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www3.topsmarkets.com/consumers/retailers/767/accounts/new?skip_verification=t'
      }
    },  
    // Weis
    {
      dpn: {
        id: 'weis',
        name: 'Weis',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip Coupon',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'weis.png',
      },
      login: {
        title:'Add your Weis Club Card Number',
        username: 'Loyalty Card Number',
        usernameType: 'card',
        legal:'Your Weis Club Card Number is your loyalty card number. No spaces, parentheses, or hyphens should be used when entering your loyalty card number. If you don\'t have a Weis Preferred Shoppers Club account, <a href="https://www.weismarkets.com/get-club-card" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.weismarkets.com/get-club-card'
      }
    },
    // Piggly Wiggly Midwest
    {
          dpn: {
            id: 'pigwigmw',
            name: 'Piggly Wiggly Midwest',
            parent: 'dpn'
          },
          storageKey: 'pbyt',
          offers: {
            mpid: {
              production: process.env.REACT_APP_MPID,
            },
            offerTag: {
              production: process.env.REACT_APP_OFFER_TAG,
            },
            cta: {
              clip:'Clip Coupon',
              clipped:'Clipped',
            }
          },
          token: '', //captcha token
          images: {
            logo: 'piggly_wiggly.png',
          },
          login: {
            title:'Add your Piggly Wiggly Rewards Card Number',
            username: 'Phone Number',
            usernameType: 'card',
            legal:'Your Piggly Wiggly Rewards Card Number is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a Piggly Wiggly Rewards card, <a href="https://www.shopthepig.com/rewards-card" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to learn how to get one.',
          },
          registration:{
            link:'https://www.shopthepig.com/rewards-card'
          }
    },  
    // Natural Grocers
    {
      dpn: {
        id: 'rinatural',
        name: 'Natural Grocers',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Add',
          clipped:'Added',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'natural_grocers.png',
      },
      login: {
        title:'Add your {N}power Account ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your {N}power Account ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have an {N}power account, <a href="https://www.naturalgrocers.com/join" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.naturalgrocers.com/join'
      }
    }, 
    // The Fresh Market
    {
      dpn: {
        id: 'thefreshmarket',
        name: 'The Fresh Market',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip To My Wallet',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'fresh_market.png',
      },
      login: {
        title:'Add your Ultimate Loyalty Experience ID',
        username: 'Phone Number',
        usernameType: 'phone',
        legal:'Your Ultimate Loyalty Experience ID is your phone number. No spaces, parentheses, or hyphens should be used when entering your phone number. If you don\'t have a The Ultimate Loyalty Experience account, <a href="https://www.thefreshmarket.com/register" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.thefreshmarket.com/register'
      }
    }, 
    // Just $ave
    {
      dpn: {
        id: 'justsave',
        name: 'Just Save',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip & Save',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'just_save.png',
      },
      login: {
        title:'Add your Just $ave Account ID',
        username: 'Email Address',
        usernameType: 'email',
        legal:'Your Just $ave Account ID is your email address. No spaces, parentheses, or hyphens should be used when entering your email address. If you don\'t have a Just $ave Loyalty account, <a href="https://www.shopjustsave.com/my-account#!/create" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.shopjustsave.com/my-account#!/create'
      }
    },
    // Kinney Drugs
    {
      dpn: {
        id: 'kinney',
        name: 'Kinney Drugs',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip It',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'kinneydrugslogo.png',
      },
      login: {
        title:'Add your tHRiVe card number',
        username: 'Membership Number',
        usernameType: 'card',
        legal:'Your tHRiVe card number is your membership number. No spaces, parentheses, or hyphens should be used when entering your membership number. If you don\'t have a tHRiVe Rewards account, <a href="https://kinneydrugs.com/accounts/register/" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://kinneydrugs.com/accounts/register/'
      }
    },
    // Big Y
    {
      dpn: {
        id: 'bigy',
        name: 'Big Y',
        parent: 'yt'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Load',
          clipped:'Loaded',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'big_y.png',
      },
      login: {
        title:'Add your Big Y Membership #',
        username: 'Membership Number',
        usernameType: 'card',
        legal:'Your Big Y Membership # is your membership number. No spaces, parentheses, or hyphens should be used when entering your membership number. If you don\'t have a Big Y account, <a href="https://www.bigy.com/rs/signup" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.bigy.com/rs/signup'
      }
    }, 
    // Shoppers
    {
      dpn: {
        id: 'shoppers',
        name: 'Shoppers',
        parent: 'dpn'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Add Item',
          clipped:'Added',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'shoppers.png',
      },
      login: {
        title:'Add your Shoppers Account ID',
        username: 'Loyalty Card Number',
        usernameType: 'card',
        legal:'Your Shoppers Account ID is your loyalty card number. No spaces, parentheses, or hyphens should be used when entering your loyalty card number. If you don\'t have a Shoppers Account, <a href="https://www.shoppersfood.com/" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://www.shoppersfood.com/'
      }
    },  
    // Raley’s
    {
      dpn: {
        id: 'raleys',
        name: 'Raley\'s',
        parent: 'yt'
      },
      storageKey: 'pbyt',
      offers: {
        mpid: {
          production: process.env.REACT_APP_MPID,
        },
        offerTag: {
          production: process.env.REACT_APP_OFFER_TAG,
        },
        cta: {
          clip:'Clip',
          clipped:'Clipped',
        }
      },
      token: '', //captcha token
      images: {
        logo: 'raleys.png',
      },
      login: {
        title:'Add your Something Extra Card ID',
        username: 'Card Number',
        usernameType: 'card',
        legal:'Your Something Extra Card ID is the first 11 digits of your card number. No spaces, parentheses, or hyphens should be used when entering your card number. If you don\'t have a Something Extra account, <a href="https://shop.raleys.com/account/profile?_ga=2.55539880.2036492385.1677811312-1046790003.1677811312" target="_blank" rel="noopener noreferrer">click here</a>&nbsp; to create one.',
      },
      registration:{
        link:'https://shop.raleys.com/account/profile?_ga=2.55539880.2036492385.1677811312-1046790003.1677811312'
      }
    } 
  ]
}
 
